import {List, Map} from 'immutable'

import {humanizedFullNames} from './nameTools'
import {personalPronoun, presentPossessionVerb, everplanOwnerPronoun, pluralPresentPossession} from './pronouns'
import {pluralize} from './tools'
import {filterResourceByAttributeId} from './plan_data/userData'

// Existence checks:
export const hasDeputies = deputyOwnerships => !deputyOwnerships.isEmpty()

export const hasDeathReporter = deputyOwnerships => hasDeputies(deputyOwnerships) && deputyOwnerships.some(deputy => deputy.get('death-reporter'))

export const hasDeferredItems = itemPermissions => itemPermissions && itemPermissions.some(itemPermission => itemPermission.get('permission') === 'deferred_read')

/**
 * Returns a list of items that will be used for managing or adding item permissions, if the current user is a client and the deputy
 * is a firm user then the `permitted-categories` should be checked, if there are any, otherwise just return all items.
 *
 * @param {boolean} isFirmUser for the firm that the current user is a client for
 * @param {List} itemResponses
 * @param {Map} firmConfig of the firm the current user is a client for and includes permitted categories
 *
 * @returns {List} itemResponses that will be used when managing or adding item permissions for a deputy
 */
export const deputyManageableItems = ({isFirmUser, itemResponses, firmConfig}) => {
  if (isFirmUser && !firmConfig.get('permitted-categories', List()).isEmpty())
    return itemResponses.filter(itemResponse => firmConfig.get('permitted-categories').includes(itemResponse.get('item-id')))
  else
    return itemResponses
}

export const isOnlyUnlocker = ({readItemsCount, deferredReadItemsCount, isUnlocker}) => !readItemsCount && !deferredReadItemsCount && isUnlocker

export const deathReportsByReportable = (deathReports, ownership) => (
  filterResourceByAttributeId({resourceList: deathReports, attribute: 'death-reportable-id', id: ownership.get('ownable-id')})
)

export const deathReportsForOwners = ({ownerships, deathReports}) => (
  ownerships.map(ownership => deathReportsByReportable(deathReports, ownership)
    .filter(deathReportForOwner => !deathReportForOwner.isEmpty())).flatten(true)
)

export const pendingDeathReport = deathReports => deathReports.find(deathReport => deathReport.get('status') === 'pending')

const isDeathReportPendingOrProcessed = deathReport => ['pending', 'processed'].includes(deathReport.get('status'))

export const pendingOrProcessedDeathReport = deathReports => deathReports.find(deathReport => isDeathReportPendingOrProcessed(deathReport))

export const pendingOrProcessedDeathReports = ({ownerships, deathReports}) => (
  deathReportsForOwners({ownerships, deathReports}).filter(deathReport => isDeathReportPendingOrProcessed(deathReport))
)

export const allDeathReportIsPendingOrProcessed = deathReports => {
  if (!deathReports.isEmpty())
    return deathReports.every(deathReport => isDeathReportPendingOrProcessed(deathReport))
}

export const ownersNotReportedDeceased = ({deathReports, ownerships}) => {
  const ownersDeathReports = deathReportsForOwners({ownerships, deathReports})
  const deathReport = pendingOrProcessedDeathReport(ownersDeathReports) || Map()

  return (
    ownerships.filter(
      ownership => !ownership.get('deceased') &&
      (ownership.get('ownable-id') !== deathReport.get('death-reportable-id') ||
      ownership.get('ownable-type') !== deathReport.get('death-reportable-type'))
    )
  )
}

export const otherOwnerIsDeceased = ({deathReports, ownerships}) => {
  const otherOwner = ownerships.find(ownership => deathReports.some(deathReport => deathReport.get('death-reportable-id') !== ownership.get('ownable-id')))
  return otherOwner && otherOwner.get('deceased')
}

export const otherOwnerDeceasedAndDeathReportIsPendingOrProcessed = ({deathReports, ownerships}) => otherOwnerIsDeceased({deathReports, ownerships}) && allDeathReportIsPendingOrProcessed(deathReports)

export const allDeathReportIsProcessed = (deathReports, ownerships) => {
  if (ownerships.size === 1 && deathReports.size === 1 || deathReports.size > 1)
    return deathReports.every(deathReport => deathReport.get('status') === 'processed')
  else
    return false
}

/** Returns a Boolean that indicates whether to show that an owner has been reported as deceased
 * @param {Boolean} isHousehold
 * @param {List} deathReports for the plan
 * @param {Map} ownership
 * @param {List} ownerships
*/
export const showOwnerReportedAsDeceased = ({isHousehold, deathReports, ownership, ownerships}) => {
  if (allDeathReportIsProcessed(deathReports, ownerships))
    return false

  return !!(
    isHousehold ?
      (pendingOrProcessedDeathReport(deathReportsByReportable(deathReports, ownership)) || ownership.get('deceased')) :
      pendingDeathReport(deathReportsByReportable(deathReports, ownership))
  )
}

/** Returns a boolean that shows if all owners of a plan are deceased or if all death reports have been processed */
export const allOwnersDeceased = ({ownerships, deathReports}) => (
  ownerships.every(ownership => ownership.get('deceased')) || allDeathReportIsProcessed(deathReports, ownerships)
)

/**
 * Used by deputies to know if any other deputy for an everplan has deferred read items
 *
 * @param {Map} everplan directly from state.api
 *
 * @returns {Boolean} whether an everplan has a deputy with deferred read items
 */
export const anyDeputyHasDeferredItems = everplan => (everplan.get('any-deputy-has-deferred-read-items'))

export const isDeathReporter = deputyOwnership => deputyOwnership.get('death-reporter')

/** Returns a boolean that indicates if all owners of an everplan have been reported as deceased and have the death reports processed or pending
 * @param {List} deathReports
 * @param {Boolean} isHousehold
 * @param {List} ownerships of the everplan
*/
export const allOwnersReported = ({deathReports, isHousehold, ownerships}) => {
  if (!isHousehold || deathReports.size > 1)
    return allDeathReportIsPendingOrProcessed(deathReports)
  else if (isHousehold && deathReports.size === 1)
    return otherOwnerDeceasedAndDeathReportIsPendingOrProcessed({deathReports, ownerships})
}


export const canUnlockPlan = deputyOwnership => {
  if (deputyOwnership.get('any-deputy-has-deferred-read-items'))
    return deputyOwnership.get('death-reporter')
  else
    return true
}

/** Return a boolean that shows whether the death report link should be shown to a deputy or not.
 * @param {Map} deputyOwnership
 * @param {List} ownerships
 * @param {Boolean} ownEverplan whether a user is viewing own Everplan or not
 * @param {List} deathReports submitted for an Everplan
 * @returns {Boolean} a boolean showing if the death report link should be shown to a deputy or not
*/
export const showReportLink = ({deputyOwnership, ownerships, ownEverplan, deathReports, isHousehold}) => (
  !ownEverplan &&
    canUnlockPlan(deputyOwnership) &&
    ownerships.some(ownership => !ownership.get('deceased')) &&
    !allOwnersReported({deathReports, ownerships, isHousehold})
)

export const remainingHouseholdMember = ({ownerToMarkDeceased, ownerships}) => (
  ownerships.find(ownership => ownership.get('id') !== ownerToMarkDeceased.get('id'))
)

/** Returns a boolean that indicates if death verification should be shown for a particular owner of an Everplan
 * @param {Map} the Everplan that is being viewed
 * @param {Map} the owner that we're checking if the death verification should be shown for
 * @param {List} all the owners of a plan
*/
export const showDeathVerification = ({userEverplan, ownerToMarkDeceased, ownerships}) => (
  anyDeputyHasDeferredItems(userEverplan) &&
  (!userEverplan.get('is-household') ||
  remainingHouseholdMember({ownerToMarkDeceased, ownerships}).get('deceased'))
)


export const pureUnlockerWithAfterDeathSectionText = (isHousehold, pronoun, ownerships) => (
  `${humanizedFullNames(ownerships)} ${presentPossessionVerb({plural: isHousehold})} locked certain parts of ${personalPronoun(pronoun, 'modifying-possessive')} Everplan until after ${personalPronoun(pronoun, 'modifying-possessive')} passing. When the time comes, submit a notice of ${personalPronoun(pronoun, 'modifying-possessive')} death, and we'll start the unlocking process based on the settings ${personalPronoun(pronoun, 'subject')} ${pluralPresentPossession({pronoun})} in place.`
)

export const planWithNoSharedOrDeferredSectionText = (ownerships, isHousehold, pronoun) => (
  `Looks like ${humanizedFullNames(ownerships)} ${isHousehold ? "haven't" : "hasn't"} shared anything from ${personalPronoun(pronoun, 'modifying-possessive')} Everplan. We'll notify you via email when new information is available.`
)

/** Return the text to show when viewing an empty plan based on the status of the Everplan and the permission level of the deputy */
export const emptyEverplanTextForDeputy = props => {
  const pronoun = everplanOwnerPronoun({isHousehold: props.isHousehold, owners: props.ownerships})
  const onlyUnlocker = isOnlyUnlocker({readItemsCount: props.readItemCount, deferredReadItemsCount: props.deferredReadItemCount, isUnlocker: props.deputyOwnership.get('death-reporter')})
  const anyDeputyHasDeferred = anyDeputyHasDeferredItems(props.deputyOwnership)
  const allDead = allOwnersDeceased({ownerships: props.ownerships, deathReports: props.deathReports})
  const allReported = allOwnersReported({deathReports: props.deathReports, isHousehold: props.isHousehold, ownerships: props.ownerships})

  if (allDead || allReported)
    return null

  if (anyDeputyHasDeferred && onlyUnlocker)
    return pureUnlockerWithAfterDeathSectionText(props.isHousehold, pronoun, props.ownerships)

  else
    return planWithNoSharedOrDeferredSectionText(props.ownerships, props.isHousehold, pronoun)
}

export const emptyEverplanText = props => (
  props.ownEverplan ?
    'Preview of your Everplan will appear here once you add information.' :
    emptyEverplanTextForDeputy(props)
)

export const deceasedHeaderEverplanInformationText = ({deferredReadItemCount, ownEverplan, readItemCount}) => {
  if (!ownEverplan) {
    const itemWord = pluralize('item', readItemCount)

    return deferredReadItemCount > 0 ?
      `${readItemCount} ${itemWord} available to you now, including ${deferredReadItemCount} previously locked ${pluralize('item', deferredReadItemCount)}.` :
      `${readItemCount} ${itemWord} available to you now.`
  }
}

export const shouldGetClientNetworkAmplifier = (userConfig, advisorContactCard) => userConfig.get('freemium') && !advisorContactCard.isEmpty()
